import React, { useEffect } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	.background {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		&:nth-child(1) {
			animation-name: fade;
			animation-delay: 5s;
			animation-duration: 3s;
			z-index: 1;
		}

		&:nth-child(2) {
			z-index: 0;
		}

		&:nth-child(n + 3) {
			display: none;
		}

		&::before {
			content: '';
			background-color: ${(props) => props.theme.colors.overlay};
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0.34;
			z-index: 1;
		}
	}

	@keyframes fade {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}
`;

const rotateBackground = () => {
	const container = document.querySelector('.backgrounds-container');
	const bgs = Array.from(container.querySelectorAll('.background'));
	let i = 0;

	const rotate = () => {
		if (i >= bgs.length) {
			i = 0;
		}

		container.appendChild(bgs[i]);

		i++;
	};

	bgs.forEach((bg) => {
		bg.addEventListener('animationend', rotate, false);
	});
};

const HomePageBackgrounds = ({ backgrounds }) => {
	useEffect(() => {
		rotateBackground();
	}, [backgrounds]);

	return (
		<StyledDiv className="backgrounds-container">
			{backgrounds.map((background) => (
				<div
					className="background"
					style={{
						backgroundImage: `url(${background.childImageSharp.fluid.src})`,
					}}
					key={`${background.childImageSharp.fluid.src}`}
				></div>
			))}
		</StyledDiv>
	);
};

export default HomePageBackgrounds;
